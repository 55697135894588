import React, { useEffect } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/core';

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import { MDXProvider } from '@mdx-js/react';

import Navigation from 'layouts/navigation';
import Footer from 'layouts/footer';

import resetCSS from './reset-css';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      smoothscroll.polyfill();
    }
  }, []);

  return (
    <MDXProvider>
      <Global styles={resetCSS} />
      <Navigation />
      <main>
        {children}
      </main>
      <Footer />
    </MDXProvider>
  );
};


export default Layout;
