import React from 'react';
import { Link } from 'gatsby';

import styled from '@emotion/styled';
import { typography, colors, mediaQuery, breakpoint } from 'styles';

import Symbol from 'assets/images/symbol.module.svg';

const NavigationWrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 10;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 6% 0;
  margin: 0 auto;
  max-width: 1400px;

  ${breakpoint(mediaQuery.tablet)} {
    padding: 48px 8% 0;
  }
`;

const Logo = styled(Symbol)`
  height: 30px;
`;

const InternalLink = styled(Link)`
  font-size: ${typography.description}px;
  font-weight: 500;
  color: ${colors.gray[50]};
  transition: .15s color ease;
  will-change: color;

  &.active,
  &:hover, &:focus {
    color: ${colors.gray[60]};
  }
`;

const ExternalLink = InternalLink.withComponent('a');

const LinkList = styled.ul`
  flex-grow: 1;
  text-align: right;

  li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 36px;
  }

  ${breakpoint(mediaQuery.pablet, 'max')} {
    display: none;
  }
`;


const Navigation = () => (
  <NavigationWrapper>
    <NavigationContainer>
      <InternalLink to='/'>
        <Logo />
      </InternalLink>
      <LinkList>
        <li>
          <InternalLink
            to='/'
            activeClassName='active'
          >
            홈
          </InternalLink>
        </li>
        <li>
          <InternalLink
            to='/curriculum/'
            activeClassName='active'
            data-gtm='click.more'
          >
            커리큘럼
          </InternalLink>
        </li>
        <li>
          <InternalLink
            to='/team/'
            activeClassName='active'
          >
            팀 소개
          </InternalLink>
        </li>
        <li>
          <ExternalLink
            href='https://drive.google.com/file/d/0B8lOtOBbOgeLMnZmUWpGRFU5aHo1Nk9CMHV4Sy1ZSXBiblFr/view?usp=sharing'
            target='_blank'
            rel='noreferrer noopener'
            data-gtm='conversion.donation'
          >
            후원하기
          </ExternalLink>
        </li>
      </LinkList>
    </NavigationContainer>
  </NavigationWrapper>
);

export default Navigation;