import React, { useEffect, useRef } from 'react';

type Target = React.RefObject<HTMLImageElement>;
type Observer = IntersectionObserver | null;

function useImageObserver(target: Target) {
  useEffect(() => {
    if (!target || !target.current) {
      return;
    }

    let observer: Observer = new window.IntersectionObserver((entries) => {
      entries.forEach((item) => {
        if (item.isIntersecting) {
          const image = item.target;

          // @ts-ignore
          Object.assign(image, { src: image.dataset.src });

          if (observer) {
            observer.unobserve(image);
          }
        }
      });
    });

    observer.observe(target.current);

    return () => {
      if (!observer) {
        return;
      }

      observer.disconnect();
      observer = null;
    };
  }, [target]);
}

export default useImageObserver;
