import React from 'react';

import styled from '@emotion/styled';
import { spacing, mediaQuery, breakpoint } from 'styles';

export const Card = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-bottom: ${spacing.chapter}px;
`;

export const CardContent = styled.div`
  margin-left: 4px;
  margin-right: 16px;

  ${breakpoint(mediaQuery.tablet)} {
    margin-right: 36px;
  }
`;