export default {
  gray: {
    10: '#fafcff' as const,
    20: '#f2f4f5' as const,
    30: '#e5e7e9' as const,
    40: '#bcc3c7' as const,
    50: '#97a1a9' as const,
    60: '#6a7780' as const,
    70: '#3e4951' as const,
    80: '#232a30' as const,
    90: '#111518' as const,
  },
  primary: {
    10: '#f2f3ff' as const,
    20: '#e5e8ff' as const,
    30: '#bdc6ff' as const,
    40: '#7185ff' as const,
    50: '#4962ff' as const,
    60: '#3d52d5' as const,
    70: '#2a3893' as const,
    80: '#03116d' as const,
    90: '#0c102a' as const,
  },
  secondary: {
    10: '#effdf7' as const,
    20: '#dffbef' as const,
    30: '#aaf6d7' as const,
    40: '#4febae' as const,
    50: '#09e48e' as const,
    60: '#0bcb7d' as const,
    70: '#009258' as const,
    80: '#005331' as const,
    90: '#001e11' as const,
  },
  yellow: {
    10: '#fee487' as const,
    20: '#fec701' as const,
    30: '#ffa900' as const,
  },
  purple: {
    10: '#c596ff' as const,
    20: '#a357ff' as const,
    30: '#8017ff' as const,
  },
  blue: {
    10: '#cbe1ff' as const,
    20: '#3366ff' as const,
    30: '#0039ea' as const,
  },
  red: {
    10: '#fe9aae' as const,
    20: '#fe4e71' as const,
  },
  orange: {
    10: '#ffb67f' as const,
    20: '#ff8426' as const,
  },
};
