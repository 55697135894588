import React from 'react';

import styled from '@emotion/styled';
import {
  colors,
  spacing,
  mediaQuery,
  breakpoint,
} from 'styles';

import {
  Subtitle,
  Description,
} from '../typography';

type Props = {
  title?: string;
  content: string | React.ReactNode;
  action?: React.ReactNode;
};

const CalloutContainer = styled.div`
  padding: 36px 28px;
  background-color: ${colors.primary[10]};
  border-radius: 8px;

  ${breakpoint(mediaQuery.pablet)} {
    display: flex;
    align-items: center;
    padding: 36px 40px;
  }
`;

const CalloutTitle = styled(Subtitle)`
  margin-bottom: ${spacing.leading}px;
  font-family: inherit;
  font-weight: bold;
  line-height: 1.5;
  color: ${colors.primary[50]};
`;

const CalloutDescription = styled(Description)`
  font-family: inherit;
  font-weight: 500;
  color: ${colors.primary[40]};
  line-height: 1.75;

  ${breakpoint(mediaQuery.pablet, 'max')} {
    margin-bottom: ${spacing.section}px;
  }
`;

const CalloutContent = styled.div`
  flex-grow: 1;
  padding-right: 24px;
`;

const Callout: React.FC<Props> = ({
  title,
  content,
  action,
}) => (
  <CalloutContainer>
    <CalloutContent>
      {title && (
        <CalloutTitle>
          {title}
        </CalloutTitle>
      )}
      <CalloutDescription>
        {content}
      </CalloutDescription>
    </CalloutContent>
    {action && (
      <div>
        {action}
      </div>
    )}
  </CalloutContainer>
);

export default Callout;
