import React from 'react';
import Helmet from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';

type Meta = {
  name: string;
  content: string;
  [key: string]: any;
}

type Props = {
  title?: string;
  description?: string;
  image?: string;
  lang?: string;
  meta?: Meta[];
};

const SEO: React.FC<Props> = ({
  title = '',
  description = '',
  image = '',
  lang = 'ko',
  meta = [],
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  );

  const metaDescription =
    description ||
    site.siteMetadata.description;

  const imageURL =
    image ||
    site.siteMetadata.image;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || site.siteMetadata.title}
      titleTemplate={title ? `%s - ${site.siteMetadata.title}` : ''}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:sitename',
          content: site.siteMetadata.title,
        },
        {
          property: 'og:image',
          content: imageURL,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'og:author',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: '스터디, 스터디그룹, 앱, 어플리케이션, iOS, Android, 웹, 웹사이트, 웹페이지, Web, 사물인터넷, IoT, 아두이노, 게임, 파이썬, 유니티, 언리얼, 자바스크립트, JavaScript, HTML, CSS, AR, 빅스비, Bixby, 방학, 여름방학, 겨울방학, 의지, 공부, 코딩, 코딩교육, SW 교육, SW, 서울, 경기, 인천, 대전',
        },
      ].concat(meta)}
    />
  );
}

export default SEO;
