import styled from '@emotion/styled';
import {
  typography,
  spacing,
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

export const Heading = styled.h1`
  font-family: inherit;
  font-size: ${typography.heading}px;
  font-weight: 900;
  line-height: 1.4;
  margin-bottom: ${spacing.leading}px;

  ${breakpoint(mediaQuery.tablet, 'max')} {
    font-size: ${typography.heading - 6}px;
  }
`;

export const Subtitle = styled.h3`
  font-family: inherit;
  font-size: ${typography.subtitle}px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: ${typography.subtitle * -0.01}px;
  color: ${colors.gray[80]};
  margin-bottom: ${spacing.leading}px;

  ${breakpoint(mediaQuery.tablet, 'max')} {
    font-size: ${typography.subtitle - 2}px;
    letter-spacing: ${(typography.subtitle - 2) * -0.01}px;
  }
`;

export const Body = styled.p`
  font-family: inherit;
  font-size: ${typography.body}px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: ${typography.body * -0.01}px;
  color: ${colors.gray[70]};

  ${breakpoint(mediaQuery.tablet, 'max')} {
    font-size: ${typography.body - 2}px;
    letter-spacing: ${(typography.body - 2) * -0.01}px;
  }
`;

export const Description = styled.p`
  font-family: inherit;
  font-size: ${typography.description}px;
  font-weight: 400;
  line-height: 1.86;
  letter-spacing: ${typography.description * -0.01}px;
  color: ${colors.gray[60]};

  ${breakpoint(mediaQuery.tablet, 'max')} {
    font-size: ${typography.description - 1}px;
    letter-spacing: ${(typography.description - 1) * -0.01}px;
  }
`;

export const Caption = styled.p`
  font-family: inherit;
  font-size: ${typography.caption}px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: ${typography.caption * -0.01}px;
  color: ${colors.gray[60]};
`;