import { css } from '@emotion/core';
import {
  colors,
  typography,
  spacing,
  breakpoint,
  mediaQuery,
} from 'styles';

export default css`
  line-height: 2;
  letter-spacing: -0.005rem;
  color: ${colors.gray[70]};

  & {
    details {
      display: block;
    }

    summary {
      display: list-itrem;
    }

    a {
      background-color: transparent;
    }

    a:active,
    a:hover {
      outline-width: 0;
    }

    strong {
      font-weight: inherit;
      font-weight: bolder;
    }

    h1 {
      font-size: 2rem;
      margin: 0.67rem 0;
    }

    img {
      border-style: none;
    }

    code,
    kbd,
    pre {
      font-family: monospace, monospace;
      font-size: ${typography.body}px;
    }

    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }

    input {
      font: inherit;
      margin: 0;
    }

    input {
      overflow: visible;
    }

    [type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }

    * {
      box-sizing: border-box;
    }

    input {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    a {
      color: ${colors.primary[50]};
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    strong {
      font-weight: bold;
    }

    hr {
      background: transparent;
      border: 0;
      border-bottom: 1px solid ${colors.gray[30]};
      height: 0;
      margin: 15px 0;
      overflow: hidden;
    }

    hr:before {
      content: '';
      display: table;
    }

    hr:after {
      clear: both;
      content: '';
      display: table;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    td,
    th {
      padding: 0;
    }

    details summary {
      cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.gray[90]};
      letter-spacing: 0;
      margin-bottom: 0;
      margin-top: 0;
    }

    h1 {
      font-size: 30px;
    }

    h1,
    h2 {
      font-weight: bold;
    }

    h2 {
      font-size: 24px;
      border-bottom: 1px solid ${colors.gray[30]};
      padding-top: 42px;
      padding-bottom: 0.3rem;
      margin-bottom: ${spacing.row * 2}px !important;
    }

    h3 {
      font-size: ${typography.subtitle}px;
    }

    h3,
    h4 {
      font-weight: 500;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    h5,
    h6 {
      font-weight: 500;
    }

    h6 {
      font-size: 12px;
      color: #6a737d;
      font-size: 0.85rem;
    }

    p {
      margin-bottom: ${spacing.leading}px !important;
      margin-top: 0;
    }

    blockquote {
      margin: 0;
    }

    ol,
    ul {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
    }

    ol ol,
    ul ol {
      list-style-type: lower-roman;
    }

    ol ol ol,
    ol ul ol,
    ul ol ol,
    ul ul ol {
      list-style-type: lower-alpha;
    }

    dd {
      margin-left: 0;
    }

    code,
    pre {
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
        monospace;
      font-size: 12px;
    }

    pre {
      margin-bottom: 0;
      margin-top: 0;
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    a:not([href]) {
      color: inherit;
      text-decoration: none;
    }

    blockquote,
    dl,
    ol,
    p,
    pre,
    table,
    ul {
      margin-bottom: ${spacing.row}px;
      margin-top: 0;
    }

    hr {
      background-color: #e1e4e8;
      border: 0;
      height: 0.25rem;
      margin: 24px 0;
      padding: 0;
    }

    blockquote {
      border-left: 0.25rem solid ${colors.gray[30]};
      color: ${colors.gray[50]};
      padding: 0 ${typography.body}px;
    }

    blockquote > :first-of-type {
      margin-top: 0;
    }

    blockquote > :first-of-type {
      margin-bottom: 0;
    }

    kbd {
      background-color: #fafbfc;
      border: 1px solid #c6cbd1;
      border-bottom-color: #959da5;
      border-radius: 3px;
      box-shadow: inset 0 -1px 0 #959da5;
      color: #444d56;
      display: inline-block;
      font-size: 11px;
      line-height: 10px;
      padding: 3px 5px;
      vertical-align: middle;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.25;
      margin-top: ${spacing.section}px;
      margin-bottom: ${spacing.row}px;
    }

    ol,
    ul {
      list-style-type: disc;
      padding-left: 28px;
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
      margin-bottom: 0;
      margin-top: 0;
    }

    li {
      word-break: keep-all;
    }

    li > p {
      margin-top: ${spacing.row}px;
    }

    li + li {
      margin-top: 0.25rem;
    }

    dl {
      padding: 0;
    }

    dl dt {
      font-size: ${typography.body}px;
      font-style: italic;
      font-weight: bold;
      margin-top: ${spacing.row}px;
      padding: 0;
    }

    dl dd {
      margin-bottom: ${spacing.row}px;
      padding: 0 ${spacing.row}px;
    }

    table {
      display: block;
      overflow: auto;
      width: 100%;
    }

    table th {
      font-weight: bold;
    }

    table td,
    table th {
      border: 1px solid #dfe2e5;
      padding: 6px 13px;
    }

    table tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;
    }

    table tr:nth-of-type(2n) {
      background-color: #f6f8fa;
    }

    img {
      background-color: #fff;
      box-sizing: content-box;
      max-width: 100%;
    }

    img[align='right'] {
      padding-left: 20px;
    }

    img[align='left'] {
      padding-right: 20px;
    }

    code {
      background-color: rgba(27, 31, 35, 0.05);
      border-radius: 3px;
      font-size: 85%;
      margin: 0;
      padding: 0.2rem 0.4rem;
    }

    pre {
      word-break: keep-all;
    }

    pre > code {
      background: transparent;
      border: 0;
      font-size: 100%;
      margin: 0;
      padding: 0;
      white-space: pre;
      word-break: normal;
    }

    .highlight {
      margin-bottom: ${spacing.row}px;
    }

    .highlight pre {
      margin-bottom: 0;
      word-break: normal;
    }

    .highlight pre,
    pre {
      background-color: #f6f8fa;
      border-radius: 3px;
      font-size: 85%;
      line-height: 1.45;
      overflow: auto;
      padding: 16px;
    }

    pre code {
      background-color: transparent;
      border: 0;
      display: inline;
      line-height: inherit;
      margin: 0;
      max-width: auto;
      overflow: visible;
      padding: 0;
      word-wrap: normal;
    }
  }

  & > *:first-of-type {
    padding-top: 0;
    margin-top: 0;
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    font-size: ${typography.body - 2}px;
    word-break: keep-all;

    h1 {
      font-size: 24px;
    }

    h1,
    h2 {
      font-weight: bold;
    }

    h2 {
      font-size: 20px;
      border-bottom: 1px solid ${colors.gray[30]};
      padding-top: 42px;
      padding-bottom: 0.3rem;
      margin-bottom: ${spacing.row * 2}px !important;
    }

    h3 {
      font-size: 16px;
    }

    h4 {
      font-size: 14px;
    }

    h5 {
      font-size: 14px;
    }
  }
`;