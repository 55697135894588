type Unit  = 'min' | 'max';

export const mediaQuery = {
  mobile: 480 as const,
  pablet: 640 as const,
  tablet: 780 as const,
  desktop: 1100 as const,
};

export const breakpoint = (
  bp: number,
  unit: Unit = 'min',
) => `@media all and (${unit}-width: ${bp + (unit === 'max' ? -1 : 0)}px)`;
