import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import styled from '@emotion/styled';
import {
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

import { Icon, Body } from 'components';

type Props = {
  title: string;
  children: React.ReactNode;
};

const AccordionTrigger = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 0;
  border-top: 1px solid ${colors.gray[30]};
  border-bottom: 1px solid ${colors.gray[30]};
`;

const AccordionContainer = styled.div`
  width: 100%;
  transition: height .2s ease;
  overflow: hidden;
  will-change: height;
  margin-top: -1px;
`;

const AccordionContent = styled.div`
  width: 100%;
  padding: 24px 0;
`;

const Accordion = ({
  title,
  children,
  ...props
}: Props) => {
  const contentRef = useRef<HTMLElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const handleToggleTrigger = useCallback(() => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  }, []);

  const handleResizeWindow = useCallback(() => {
    if (contentRef && contentRef.current) {
      const height = contentRef.current.clientHeight;
      setHeight(height);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    handleResizeWindow();

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return (
    <div {...props}>
      <AccordionTrigger
        className={
          isOpen
            ? 'Accordion__Trigger--opened'
            : 'Accordion__Trigger--closed'
        }
        onClick={handleToggleTrigger}
      >
        <Body
          css={{
            flexGrow: 1,
            fontWeight: 500,
            textAlign: 'left',
            color: isOpen ? colors.gray[90] : colors.gray[60],
          }}
        >
          {title}
        </Body>
        <Icon
          type='keyboard_arrow_down'
          css={{
            transition: 'transform .2s ease',
            transform: isOpen ? 'rotate(180deg)' : undefined,
            color: isOpen ? colors.gray[50] : colors.gray[30],
            fontSize: '20px !important',

            [breakpoint(mediaQuery.tablet)]: {
              marginRight: '16px',
            },
          }}
        />
      </AccordionTrigger>
      <AccordionContainer
        style={{
          height: isOpen ? `${height}px` : '0px',
        }}
      >
        <AccordionContent ref={contentRef}>
          {children}
        </AccordionContent>
      </AccordionContainer>
    </div>
  );
};

export default Accordion;