import React from 'react';

import clsx from 'clsx';
import styled from '@emotion/styled';

type Props = {
  type: string;
  className?: string;
  [prop: string]: any;
};

const I = styled.i`
  font-size: inherit;
  margin-top: -1px;
`;

const Icon: React.FC<Props> = ({
  type,
  className,
  ...props
}) => (
  <I
      className={clsx('Icon', 'material-icons-round', className)}
    {...props}
  >
    {type}
  </I>
);

export default Icon;