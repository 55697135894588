import React from 'react';

import styled from '@emotion/styled';

import styles from './Button.style';

type ButtonVarient ='basic' | 'primary' | 'submit';
type ButtonSize = 'small' | 'medium' | 'large';
type ButtonShape = 'base' | 'outline' | 'text';

type BaseButtonProps = {
  type?: ButtonVarient;
  size?: ButtonSize;
  shape?: ButtonShape;
};

// Support Native Buttons
type ButtonHTMLType = 'submit' | 'button' | 'reset';

type NativeButton = {
  htmlType?: ButtonHTMLType;
  onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps &
  Omit<React.ButtonHTMLAttributes<any>, 'type' | 'onClick'>;

type AnchorButton = {
  href: string;
  target?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps &
  Omit<React.AnchorHTMLAttributes<any>, 'type' | 'onClick'>;

// Combined Props
type Props = Partial<AnchorButton & NativeButton>;


// @ts-ignore
const Button = styled.button<Props>`
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.15s color ease, 0.15s background-color ease;
  will-change: color, background-color;

  ${({ size = 'medium' }: Props) => {
    const height = styles.height[size];
    const textSize = styles.textSize[size];

    return `
      padding: 0 ${height / 3}px;
      line-height: ${height}px;
      height: ${height}px;
      font-size: ${textSize}px;
      letter-spacing: ${textSize * -0.01}px;
    `;
  }};

  ${({ type = 'basic', shape = 'base', disabled }: Props) => {
    const colorType = disabled ? 'disabled' : type;

    const { background, text } = styles[colorType];

    const color = shape === 'base' ? text : background;
    const backgroundColor = shape === 'base' ? background : 'white';
    const border = shape === 'outline' ? `1px solid ${color}` : 'none';

    return `
      color: ${color};
      background-color: ${backgroundColor};
      border: ${border};
    `;
  }}
  cursor: ${({ disabled }: Props) => (disabled ? 'disabled' : 'pointer')};

  &:hover,
  &:focus {
    ${({ type = 'basic', shape = 'base', disabled }: Props) => {
      const colorType = disabled ? 'disabled' : type;

      const { background, text, focus, hover } = styles[colorType];

      const color = shape === 'text' ? background : text;
      const backgroundColor = shape === 'text' ? hover : focus;
      const border = shape === 'outline' ? `1px solid ${color}` : 'none';

      return `
      color: ${color};
      background-color: ${backgroundColor};
      border: ${border};
    `;
    }}
  }

  .Icon {
    font-size: ${({ size = 'medium' }: Props) => styles.textSize[size]}px;
  }

  span + .Icon {
    margin-left: 8px;
  }

  .Icon + span {
    margin-left: 8px;
  }
`;

Button.defaultProps = {
  type: 'basic',
  shape: 'base',
};

export default Button;
