import React,{
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import styled from '@emotion/styled';
import { colors } from 'styles';
import { useImageObserver } from 'hook';

type Props = React.ImgHTMLAttributes<any>;

type ImageViewProps = {
  isLoaded: boolean;
};

const ImageView = styled.img<ImageViewProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: ${({ isLoaded }: ImageViewProps) => isLoaded ? 1 : 0};
  transition: .2s opacity ease;
`;

const ImageContainer = styled.div`
  position: relative;
  display: block;
  border-radius: 4px;
  background-color: ${colors.gray[20]};
  overflow: hidden;
`;

const Image = ({
  src,
  alt,
  ...props
}: Props) => {
  const containerRef = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleSetLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    setIsLoaded(false);
  }, [src]);

  useImageObserver(containerRef);

  return (
    <ImageContainer {...props}>
      <ImageView
        ref={containerRef}
        isLoaded={isLoaded}
        data-src={src}
        alt={alt}
        onLoad={handleSetLoad}
      />
    </ImageContainer>
  );
};

export default Image;