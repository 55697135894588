import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import {
  typography,
  spacing,
  colors,
  mediaQuery,
  breakpoint,
} from 'styles';

import { Icon, Description } from 'components';

import Logo from 'assets/images/logo.module.svg';
import FBIcon from 'assets/images/ic_logo_facebook.module.svg';
import IGIcon from 'assets/images/ic_logo_instagram.module.svg';
import MailIcon from 'assets/images/ic_mail.module.svg';

const FooterContainer = styled.footer`
  display: flex;
  align-items: flex-start;
  margin-top: ${spacing.chapter * 3}px;
  padding: ${spacing.chapter}px 6%;
  background-color: ${colors.gray[20]};
  color: ${colors.gray[40]};

  ${breakpoint(mediaQuery.tablet, 'max')} {
    flex-direction: column-reverse;
  }

  ${breakpoint(mediaQuery.tablet)} {
    padding: ${spacing.chapter}px 8%;
  }
`;

const FooterFlexArea = styled.div`
  flex-grow: 1;

  ${breakpoint(mediaQuery.tablet, 'max')} {
    width: 100%;
    text-align: center;
  }
`;

const TextLink = Description.withComponent('a');

const IconLink = styled.a`
  margin-left: 20px;
  opacity: 0.6;

  &:first-of-type {
    margin: 0 !important;
  }

  &:hover {
    opacity: 1;
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    margin-left: 28px;
  }
`;

const FooterLinkRow = styled.div`
  text-align: right;

  ${TextLink} {
    margin-left: 20px;
    font-weight: 500;
    color: ${colors.gray[60]};

    &:first-of-type {
      margin: 0 !important;
    }

    &:hover, &:focus {
      color: ${colors.gray[70]};
    }
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    width: 100%;
    text-align: center;
    margin-bottom: ${spacing.section}px;
  }
`;

const FooterLogo = styled(Logo)`
  display: block;
  margin-bottom: 30px;

  path {
    fill: ${colors.gray[40]};
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    display: none;
  }
`;

const FooterCopyright = styled.p`
  font-size: ${typography.caption}px;
`;

const ScrollTopButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  margin-bottom: ${spacing.section}px;
  background-color: ${colors.gray[30]};
  border: 1px solid transparent;
  border-radius: 50%;
  transition: .15s all ease;

  .Icon {
    margin: 0;
    font-size: 16px;
    color: ${colors.gray[40]};
  }

  &:hover, &:focus {
    border-color: ${colors.gray[30]};
    background-color: transparent;
  }

  ${breakpoint(mediaQuery.tablet)} {
    display: none;
  }
`;

const IconStyle = {
  height: '20px',
};

const Footer = () => {
  const handleClickButton = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <FooterContainer>
      <FooterFlexArea>
        <FooterLogo />
        <ScrollTopButton
          onClick={handleClickButton}
        >
          <Icon type='arrow_upward' />
        </ScrollTopButton>
        <FooterCopyright>
          Copyright ⓒ 2015 설리번교육연구소. All Rights Reserved.
      </FooterCopyright>
      </FooterFlexArea>
      <FooterLinkRow>
        <div css={{ marginBottom: `${spacing.row}px` }}>
          <TextLink
            href='http://sullivan-project-terms.surge.sh/privacy.html'
            target='_blank'
            rel='noreferrer noopener'
          >
            개인정보보호
        </TextLink>
          <TextLink
            href='http://sullivan-project-terms.surge.sh/service.html'
            target='_blank'
            rel='noreferrer noopener'
          >
            약관
        </TextLink>
          <TextLink
            href='https://drive.google.com/file/d/0B8lOtOBbOgeLMnZmUWpGRFU5aHo1Nk9CMHV4Sy1ZSXBiblFr/view?usp=sharing'
            target='_blank'
            rel='noreferrer noopener'
            data-gtm='conversion.donation'
          >
            후원하기
        </TextLink>
        </div>
        <div>
          <IconLink
            href='https://fb.com/sullivanproject.in'
            target='_blank'
            rel='noreferrer noopener'
          >
            <FBIcon css={IconStyle} />
          </IconLink>
          <IconLink
            href='https://instagr.am/sullivanproject'
            target='_blank'
            rel='noreferrer noopener'
          >
            <IGIcon css={IconStyle} />
          </IconLink>
          <IconLink
            href='mailto:contact@sullivanproject.in'
          >
            <MailIcon css={IconStyle} />
          </IconLink>
        </div>
      </FooterLinkRow>
    </FooterContainer>
  );
};

export default Footer;