import { colors, typography } from 'styles';

export default {
  basic: {
    background: colors.gray[20],
    text: colors.gray[60],
    focus: colors.gray[30],
    hover: colors.gray[20],
  },
  primary: {
    background: colors.primary[50],
    text: 'white',
    focus: colors.primary[60],
    hover: colors.primary[10],
  },
  submit: {
    background: colors.secondary[60],
    text: 'white',
    focus: colors.secondary[70],
    hover: colors.secondary[10],
  },
  disabled: {
    background: colors.gray[30],
    text: colors.gray[40],
    focus: colors.gray[30],
    hover: 'white',
  },
  textSize: {
    small: typography.description,
    medium: typography.body,
    large: typography.body,
  },
  height: {
    small: 40,
    medium: 48,
    large: 56,
  },
};
