import React from 'react';

import styled, { StyledComponent } from '@emotion/styled';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import markdownStyle from './Markdown.style';

type Props = {
  children: string;
  css?: string | {},
};

const MarkdownContainer = styled.section``;

const Markdown = ({
  css,
  children,
}: Props) => (
  <MarkdownContainer
    css={[markdownStyle, css]}
  >
    <MDXRenderer>
      {children}
    </MDXRenderer>
  </MarkdownContainer>
);

export default Markdown;
