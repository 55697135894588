export const copy = (text: string) => {
  const textarea = document.createElement('textarea');
  Object.assign(textarea, { value: text });

  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);

  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const shuffle = (a: any[]) => {
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }

  return a;
};