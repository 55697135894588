import colors from './colors';
import typography from './typography';
import spacing from './spacing';
import { mediaQuery, breakpoint } from './media-query';

import './reset.css';

export {
  colors,
  typography,
  spacing,
  mediaQuery,
  breakpoint,
};