import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';

import styled from '@emotion/styled';
import { colors } from 'styles';

type Props = {
  placeName: string;
  address: string;
};

const MapContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.gray[20]};
  border-radius: 4px;
`;

const MapArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Map = ({
  placeName,
  address,
  ...props
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const kakao = useRef<any>({});
  const map = useRef<any>({});
  const place = useRef<any>({});

  const displayMarker = useCallback((placeData) => {
    const marker = new kakao.current.Marker({
      map: map.current,
      position: new kakao.current.LatLng(placeData.y, placeData.x),
    });

    const url = `http://map.kakao.com/link/map/${placeName},${placeData.y},${placeData.x}`;

    kakao.current.event.addListener(marker, 'click', () => {
      open(url);
    });
  }, [placeName]);

  const getPlace = useCallback(([data], status) => {
    if (
      !data ||
      status !== kakao.current.services.Status.OK
    ) {
      return;
    }

    const bounds = new kakao.current.LatLngBounds();
    displayMarker(data);

    bounds.extend(new kakao.current.LatLng(data.y, data.x));
    map.current.setBounds(bounds);
    map.current.setLevel(4);
  }, []);

  const getMapData = useCallback(() => {
    const center = new kakao.current.LatLng(37.566826, 126.9786567);
    const kakaoMap = new kakao.current.Map(containerRef.current, {
      center,
      level: 4,
    });

    map.current = kakaoMap;

    const kakaoPlace = new kakao.current.services.Geocoder();
    place.current = kakaoPlace;

    place.current.addressSearch(
      address,
      getPlace,
    );
  }, [address]);


  useEffect(() => {
    window.kakao.maps.load(() => {
      kakao.current = window.kakao.maps;
      getMapData();
    });
  }, [address]);


  return (
    <MapContainer {...props}>
      <MapArea
        ref={containerRef}
      />
    </MapContainer>
  );
};

export default Map;
